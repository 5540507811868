"use client";

import type { ReactNode } from "react";

import { useIsDesktopViewport } from "../../../hooks";
import type { WithStylexArray } from "../../../types";
import { Button } from "../../_base";

type AuthSubmitProps = WithStylexArray<{
  disabled: boolean;
  children: ReactNode;
}>;

export const AuthSubmitButton = ({
  disabled,
  children,
  styleXArray,
}: AuthSubmitProps) => {
  const isDesktop = useIsDesktopViewport();
  return (
    <Button
      type="submit"
      disabled={disabled}
      size="l"
      styleXArray={styleXArray}
      isFullWidth={!isDesktop}
    >
      {children}
    </Button>
  );
};
