"use client";

import { usePathname, useRouter, useSearchParams } from "next/navigation";
import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";

export type UseSearchParamStateProps<
  OptionsMultipleType extends boolean = false,
  ValueType extends string = string,
> = {
  defaultValue?: OptionsMultipleType extends true ? ValueType[] : ValueType;
  key: string;
  multiple?: OptionsMultipleType;
  removeFromUrlIfDefaultValue?: boolean;
};

export const useSearchParamState = <
  OptionsMultipleType extends boolean = false,
  ValueType extends string = string,
>({
  defaultValue,
  key,
  multiple,
  removeFromUrlIfDefaultValue,
}: UseSearchParamStateProps<OptionsMultipleType>) => {
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();

  const [state, setState] = useState<
    UseSearchParamStateProps<OptionsMultipleType>["defaultValue"]
  >(
    (multiple
      ? searchParams.getAll(key)?.length > 0
        ? searchParams.getAll(key)
        : (defaultValue ?? [])
      : searchParams.get(key)?.length > 0
        ? searchParams.get(key)
        : (defaultValue ??
          "")) as UseSearchParamStateProps<OptionsMultipleType>["defaultValue"],
  );

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    const old = multiple
      ? newSearchParams.getAll(key)
      : newSearchParams.get(key);
    const replace = !old && !removeFromUrlIfDefaultValue;

    // update search params IF either there was none and we want to show it,
    // OR there was none and state is no longer the default value,
    // OR there was one but it is different from what state is now
    const update =
      (!old && (!removeFromUrlIfDefaultValue || state !== defaultValue)) ||
      JSON.stringify(old) !== JSON.stringify(state);

    if (update) {
      newSearchParams.delete(key);

      if (
        !(
          removeFromUrlIfDefaultValue &&
          JSON.stringify(defaultValue) === JSON.stringify(state)
        )
      ) {
        if (multiple && state.length !== 0)
          (state as string[]).forEach((stateValue) => {
            if (stateValue.length > 0) newSearchParams.append(key, stateValue);
          });
        else if (state.length > 0) newSearchParams.set(key, state as string);
      }

      newSearchParams.sort();

      const path = `${pathname}${newSearchParams.toString() ? "?" + newSearchParams.toString() : ""}`;
      if (typeof history !== "undefined") {
        const url = new URL(path, window.location.href);
        if (replace) {
          history.replaceState(null, "", url);
        } else {
          history.pushState(null, "", url);
        }
      } else {
        if (replace) {
          router.replace(path);
        } else {
          router.push(path);
        }
      }
    }
  }, [state]);

  useEffect(() => {
    const paramValue = multiple
      ? searchParams.getAll(key)?.length > 0
        ? searchParams.getAll(key)
        : (defaultValue ?? [])
      : searchParams.get(key)?.length > 0
        ? searchParams.get(key)
        : (defaultValue ?? "");

    if (JSON.stringify(paramValue) !== JSON.stringify(state)) {
      setState(
        paramValue as UseSearchParamStateProps<OptionsMultipleType>["defaultValue"],
      );
    }
  }, [searchParams]);

  return [state, setState] as [
    UseSearchParamStateProps<OptionsMultipleType, ValueType>["defaultValue"],
    Dispatch<
      SetStateAction<
        UseSearchParamStateProps<OptionsMultipleType, ValueType>["defaultValue"]
      >
    >,
  ];
};
