"use client";

import type { Login } from "@packages/sdk";
import { useSession } from "@packages/sdk";
import { useSearchParams } from "next/navigation";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";

import { useSearchParamState } from "../../../hooks";
import { AuthMainFlow } from "./AuthMainFlow";
import type { AuthFlow, AuthMethod, AuthProps } from "./AuthTypes";
import { EmailAuthFlow } from "./EmailAuthFlow";
import { PasswordAuthFlow } from "./PasswordAuthFlow";
import { PhoneAuthFlow } from "./PhoneAuthFlow";
import { PhoneCodeAuthFlow } from "./PhoneCodeAuthFlow";

type AuthComponentProps = AuthProps & {
  onFlowChange?: (flow: AuthFlow) => void;
};

export const Auth = ({
  onFlowChange: handleFlowChange,
  allowedMethods = ["email", "phone", "apple", "google"],
  hideHeader,
  onAuth,
  ...props
}: AuthComponentProps) => {
  const initialFlow = props.email ? "email" : props.phone ? "phone" : "main";
  const [flow, setFlow] = useSearchParamState<false, AuthFlow>({
    defaultValue: initialFlow,
    key: "flow",
    removeFromUrlIfDefaultValue: false,
    multiple: false,
  });
  const [state, setState] = useState<Record<string, any>>({});
  const [initialCheck, setInitialCheck] = useState<boolean>(false);
  const { status, token, setToken, setStatus } = useSession();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (!initialCheck) {
      // on initial load only, don't interfere with regular operations
      if (status === "authenticated") {
        // auth method is unknown
        onAuth?.(null, { oauth: { access_token: token } });
      }
      if (status !== "loading") setInitialCheck(true);
    }
  }, [status, initialCheck]);

  useEffect(() => {
    const urlFlow = searchParams.get("flow");
    if (
      urlFlow !== flow &&
      ["email", "phone", "phone_code", "password", "main"].includes(urlFlow)
    ) {
      setFlow(urlFlow as AuthFlow);
      handleFlowChange?.(urlFlow as AuthFlow);
    }
  }, [searchParams]);

  const handleAuth = (method: AuthMethod, data: Login) => {
    if (data?.oauth?.access_token) {
      setToken(data.oauth.access_token);
      setStatus("authenticated");
    }
    return onAuth?.(method, data);
  };

  const onFlowChange = (flow: AuthFlow) => {
    setFlow(flow);
    handleFlowChange?.(flow);
  };

  const flowProps = {
    ...props,
    onAuth: handleAuth,
    hideHeader,
    allowedMethods,
    flow,
    setFlow: onFlowChange,
    state,
    setState,
  };

  const flows: Record<AuthFlow, ReactNode> = {
    main: <AuthMainFlow {...flowProps} />,
    email: <EmailAuthFlow {...flowProps} />,
    password: <PasswordAuthFlow {...flowProps} />,
    phone: <PhoneAuthFlow {...flowProps} />,
    phone_code: <PhoneCodeAuthFlow {...flowProps} />,
  };

  return flows[flow];
};

Auth.displayName = "Auth";
