"use client";

import { useModal } from "@ebay/nice-modal-react";
import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import { usePlayer } from "@packages/media";
import type { TrustedCollection } from "@packages/sdk";
import {
  redirectToAuth,
  useBranch,
  useRedirects,
  useRequestCollection,
  useRequestMe,
  useSession,
} from "@packages/sdk";
import type { MouseEvent } from "react";

import {
  Button,
  PlayIcon,
  ShareModal,
  SubscriptionIcon,
} from "../../../../_base";
import { JoinChallengeButton } from "../../JoinChallengeButton";

export type ChallengeCTAButtonProps = {
  collection: Pick<
    TrustedCollection,
    "available_at" | "id" | "images" | "paid" | "title"
  >;
  challengeId: number;
};

export const ChallengeCTAButton = ({
  collection,
  challengeId,
}: ChallengeCTAButtonProps) => {
  const analytics = useAnalytics();
  const t = useTranslations();
  const { status } = useSession();
  const { query: user } = useRequestMe();
  const { query: challenge } = useRequestCollection({ id: collection.id });
  const player = usePlayer();
  const shareModal = useModal(ShareModal);
  const branch = useBranch();
  const { redirectToSubscribe } = useRedirects();
  const needsToSubscribeToListen =
    collection.paid && !Boolean(user?.data?.subscription);

  const hasStarted = new Date() > new Date(collection.available_at);

  const playCollection = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (status === "authenticated") {
      if (needsToSubscribeToListen) {
        return redirectToSubscribe();
      }

      await player?.playNew({ type: "challenge", id: challengeId });
    } else {
      redirectToAuth();
    }
  };

  if (status === "unauthenticated" || !challenge?.data?.has_joined) {
    return <JoinChallengeButton collection={collection} />;
  }

  if (!hasStarted) {
    return (
      <Button
        variant="white"
        onPointerDown={() => {
          analytics.track({
            event: HallowAnalyticsEvent.TappedInviteFriends,
            properties: {},
          });
        }}
        onClick={async () =>
          shareModal.show({
            color: collection.images.color_hex,
            imgSrc: collection.images.large,
            title: collection.title,
            link: await branch.collection({ id: collection.id }),
          })
        }
      >
        {t("web_share_challenge_invite_button_text")}
      </Button>
    );
  }

  return (
    <Button
      onClickCapture={playCollection}
      variant="white"
      startIcon={
        needsToSubscribeToListen ? (
          <SubscriptionIcon.Filled />
        ) : (
          <PlayIcon.Small />
        )
      }
    >
      {t(
        user?.data?.subscription
          ? "play_session"
          : collection.paid
            ? "button_subscribe_to_listen"
            : "button_listen_for_free",
      )}
    </Button>
  );
};
