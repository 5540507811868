"use client";

import { useLocale } from "@packages/i18n";
import type { Cart } from "@packages/sdk";

export const useLineItemFormatter = ({
  currency = "USD",
}: Pick<Cart, "currency">) => {
  const locale = useLocale();

  return new Intl.NumberFormat(locale, { style: "currency", currency });
};
