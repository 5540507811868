"use client";

import Bugsnag from "@bugsnag/js";
import type {
  PlayRequestItem,
  QueueContextShape,
  QueueHlsObject,
  QueueManagedItem,
} from "@packages/sdk";
import {
  keyCollection,
  keyPrayer,
  useLocalStorageState,
  useRequestBackgroundSounds,
  useRequestMe,
  useRequestSession,
} from "@packages/sdk";
import { useQueryClient } from "@tanstack/react-query";
import type { MediaPlaylist } from "hls.js";
import type {
  ComponentPropsWithoutRef,
  Dispatch,
  EventHandler,
  FC,
  ForwardRefExoticComponent,
  MutableRefObject,
  RefAttributes,
  SetStateAction,
} from "react";
import { createContext, useCallback, useEffect, useRef, useState } from "react";
import isEqual from "react-fast-compare";
import { v4 as uuid } from "uuid";

import { useCurrentQueueItem, useMediaAnalytics } from "../../hooks";
import type { UseMediaSessionAPIReturn } from "../../hooks/useMediaSessionAPI";
import { useMediaSessionAPI } from "../../hooks/useMediaSessionAPI";
import { getContentId, getContentImage, getContentTitle } from "../../lib";
import type {
  MediaElementsProps,
  MediaElementsRef,
  MediaPlayerProps,
} from "../../types";
import { MediaTextTrack } from "../../types/MediaTextTrack.types";
import { useCompletionCache } from "../CompletionCacheProvider";

export type MediaContextShape = {
  // these properties are used by players and other children
  addToQueue: QueueContextShape["addToQueue"];
  autoResumed: boolean;
  readonly bgId: number | null;
  readonly bgTitle: string | null;
  readonly bgVolume: number;
  cancelSleepTimer: () => void;
  readonly currentTime: number;
  display: Display;
  readonly duration: number;
  goToQueueId: (uuid: string) => Promise<void>;
  inTransition: boolean;
  isPostPrayerDownloadModalReady: boolean;
  mediaRef: MutableRefObject<MediaElementsRef>;
  readonly mediaType: "audio" | "video";
  pause: () => void;
  readonly paused: boolean;
  /**
   * Start the media player immediately
   * @returns {Promise<void>}
   */
  play: () => Promise<void>;
  /**
   * Start the media player AFTER waiting for it to load new content.
   * @returns {Promise<void>}
   */
  playNew: (item: PlayRequestItem) => Promise<void>;
  restart: () => void;
  setBgId: Dispatch<SetStateAction<null | number>>;
  setBgVolume: (v: number) => void;
  setIsPostPrayerDownloadModalReady: Dispatch<SetStateAction<boolean>>;
  setSleepTimer: (seconds: number, timeMinStr: string) => void;
  setSpeed: (s: number) => void;
  setSubtitles: (subtitleIndex: number) => void;
  setTime: (t: number) => void;
  setVolume: (v: number) => void;
  skipBack: () => void;
  skipForward: () => Promise<void>;
  skipIntro: () => void;
  switchTo: QueueContextShape["swap"];
  readonly sleepTimerStart: number | null;
  readonly sleepTimerTarget: number | null;
  readonly speed: number;
  readonly subtitleIdx: number | null;
  readonly subtitles: Array<MediaTextTrack>;
  readonly volume: number;
};

export type MediaElementContextShape = {
  // these properties are used exclusively by MediaElements component only
  onLoaded: () => void;
  onBgLoaded: () => void;
  onEnded: () => Promise<void>;
  onError: (err: DOMException | MediaError | Error) => Promise<void>;
  onElementError: (event: any) => Promise<void>;
  onBgError: (err: DOMException | MediaError) => Promise<void>;
  onBgElementError: (event: any) => Promise<void>;
  updateDuration: (d: number) => void;
  updatePaused: (p: boolean) => void;
  updateSpeed: (s: number) => void;
  updateSubtitles: (tracks: Array<MediaPlaylist> | TextTrackList) => void;
  updateTime: (t: number) => void;
  updateVolume: (v: number) => void;
};

export const MediaContext = createContext<MediaContextShape>({
  addToQueue: () => Promise.resolve([]),
  autoResumed: false,
  bgId: 0,
  bgTitle: "",
  bgVolume: 0,
  cancelSleepTimer: () => {},
  currentTime: 0,
  display: "hidden",
  duration: 0,
  goToQueueId: () => Promise.resolve(),
  inTransition: false,
  isPostPrayerDownloadModalReady: false,
  mediaRef: { current: null },
  mediaType: undefined,
  pause: () => {},
  paused: false,
  play: () => Promise.resolve(),
  playNew: () => Promise.resolve(),
  restart: () => {},
  setBgId: () => {},
  setBgVolume: () => {},
  setIsPostPrayerDownloadModalReady: () => {},
  setSleepTimer: () => {},
  setSpeed: () => {},
  setSubtitles: () => {},
  setTime: () => {},
  setVolume: () => {},
  skipBack: () => {},
  skipForward: () => Promise.resolve(),
  skipIntro: () => {},
  sleepTimerStart: 0,
  sleepTimerTarget: 0,
  speed: 0,
  switchTo: () => Promise.resolve(),
  subtitleIdx: null,
  subtitles: [],
  volume: 0,
});
export const MediaElementContext = createContext<MediaElementContextShape>({
  onLoaded: () => {},
  onBgLoaded: () => {},
  onEnded: () => Promise.resolve(),
  onError: () => Promise.resolve(),
  onElementError: () => Promise.resolve(),
  onBgError: () => Promise.resolve(),
  onBgElementError: () => Promise.resolve(),
  updateDuration: () => {},
  updatePaused: () => {},
  updateSpeed: () => {},
  updateSubtitles: () => {},
  updateTime: () => {},
  updateVolume: () => {},
});

export type MediaProviderProps = Omit<
  ComponentPropsWithoutRef<typeof MediaContext.Provider>,
  "value"
> & {
  Player: FC<MediaPlayerProps>;
  Elements: ForwardRefExoticComponent<
    MediaElementsProps & RefAttributes<MediaElementsRef>
  >;
};

export type Display = "collapsed" | "expanded" | "fullscreen" | "hidden";

const MEDIA_TRANSITION_MS = 250;

export const MediaProvider = ({
  children,
  Player,
  Elements,
}: MediaProviderProps) => {
  //#region Contexts
  const {
    currentItem,
    queue: {
      addToQueue,
      clearQueue,
      forward,
      back,
      refresh,
      newQueue,
      skipTo,
      swap,
    },
  } = useCurrentQueueItem();
  const { query: backgrounds } = useRequestBackgroundSounds();
  const { query: user } = useRequestMe();
  const { requestPut: requestUpdateMediaTime } = useRequestSession();
  const [bgId, setBgId] = useLocalStorageState<number | null>({
    defaultValue: null,
    key: "bgAudio",
  });
  const queryClient = useQueryClient();
  //#endregion
  //#region Refs
  const mediaRef = useRef<MediaElementsRef>(null);
  const sleepTimer = useRef<NodeJS.Timeout | null>(null);
  const loadRequestPromiseResolver = useRef<Record<string, () => void>>({});
  const bgLoadRequestPromiseResolver = useRef<Array<() => void>>([]);
  // these need to be refs to avoid refreshing the media elements
  const backendPingTime = useRef<number>(0);
  const audioUuid = useRef<string | null>(null);
  const currentItemRef = useRef<QueueManagedItem | null>(currentItem);
  const goForwardRef = useRef<((skip: boolean) => Promise<void>) | null>(null);
  const displayRef = useRef<Display>("hidden");
  const mediaSessionRef = useRef<UseMediaSessionAPIReturn | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const fsChangeHandler = useRef<EventHandler<any>>(() => {});
  const forceBgUpdate = useRef<boolean>(false);
  const bgAudioSrcRef = useRef<typeof bgAudioSrc>(null);
  //#endregion

  const {
    playerDestroyed,
    playerInitialized,
    playerBeganTrackRef,
    playerEndedTrackRef,
    tappedFFWRef,
    tappedRWRef,
    tappedSkipBackRef,
    tappedSkipForwardRef,
    audioStartedRef,
    audioStartDelayed,
    draggedTimeSlider,
    maximizedPlayer,
    minimizedPlayer,
    tappedPause,
    tappedPlay,
    timerEndedRef,
  } = useMediaAnalytics({
    mediaRef,
    backgroundTitle: bgId
      ? (backgrounds.data?.find((qi) => qi.selected_audio.id === bgId)?.prayer
          .title ?? null)
      : null,
  });

  //#region States
  // why do we use all these state values instead of just relying on whatever mediaRef says? Because React doesn't...
  // react... to changes in a ref object. We need a bunch of the app to update when these things change, which means
  // they need to be states in the context, not just the media ref.
  const [autoResumed, setAutoResumed] = useState<boolean>(false);
  const [bgAudioSrc, setBgAudioSrc] = useState<
    [QueueHlsObject | null, string | null]
  >([null, null]);
  const [bgVolume, setBgVolume] = useState<number>(0.25);
  const [currentTime, setCurrentTime] = useState<number>(
    mediaRef.current?.currentTime ?? 0,
  );
  const [display, setDisplay] = useState<Display>("hidden");
  const [duration, setDuration] = useState<number>(
    mediaRef.current?.duration ?? 0,
  );
  const [inTransition, setInTransition] = useState<boolean>(false);
  const [isPostPrayerDownloadModalReady, setIsPostPrayerDownloadModalReady] =
    useState(false);
  const [mediaSrc, setMediaSrc] = useState<
    [QueueHlsObject | null, string | null]
  >([
    currentItem?.selected_audio.hls ?? null,
    currentItem?.selected_audio.url ?? null,
  ]);
  const [mediaType, setMediaType] = useState<"audio" | "video">("audio");
  const [paused, setPaused] = useState<boolean>(
    mediaRef.current?.paused ?? true,
  );
  const [sleepTimerStart, setSleepTimerStart] = useState<number | null>(null);
  const [sleepTimerTarget, setSleepTimerTarget] = useState<number | null>(null);
  const [speed, setSpeed] = useState<number>(
    mediaRef.current?.playbackRate ?? 1,
  );
  const [volume, setVolume] = useLocalStorageState<number>({
    key: "volume",
    defaultValue: mediaRef.current?.volume ?? 1,
  });
  const [subtitles, setSubtitles] = useState<Array<MediaTextTrack>>([]);
  const [subtitleIdx, setSubtitleIdx] = useState<number | null>(null);
  const { complete } = useCompletionCache();
  const [videoWidth, setVideoWidth] = useState<number>(
    mediaRef.current?.width ?? 64,
  );
  //#endregion
  //#region Callback dependencies
  const play = async (rerun = false) => {
    if (!rerun)
      tappedPlay(
        displayRef.current === "collapsed" ? "minimized" : "maximized",
      );
    if (
      currentItemRef.current.selected_audio.media_type === "video" &&
      displayRef.current === "collapsed" &&
      (!mediaRef.current ||
        mediaRef.current.currentTime === 0 ||
        mediaRef.current.currentTime === mediaRef.current.duration)
    ) {
      await updateDisplay("expanded");
    }
    if (mediaRef.current) {
      const requestAt = Date.now();
      await mediaRef.current.play();
      if (Date.now() - requestAt > 5000) {
        audioStartDelayed();
      }
    } else if (!rerun) {
      return new Promise<void>((resolve) => {
        setTimeout(async () => {
          resolve(await play(true));
        }, MEDIA_TRANSITION_MS);
      });
    }
    return Promise.resolve();
  };

  const pause = (wasNotTapped = false) => {
    if (!wasNotTapped)
      tappedPause(
        displayRef.current === "collapsed" ? "minimized" : "maximized",
      );
    if (mediaRef.current) return mediaRef.current.pause();
  };

  const updateDisplay = async (newDisplay: Display) => {
    setInTransition(true);

    if (newDisplay === "fullscreen") {
      if (containerRef.current) {
        try {
          const oldDisplay = displayRef.current;
          if (
            containerRef.current.requestFullscreen ||
            // @ts-ignore
            containerRef.current.webkitRequestFullscreen
          ) {
            if (containerRef.current.requestFullscreen) {
              try {
                await containerRef.current.requestFullscreen();
              } catch (e) {
                console.error(e);
                newDisplay = oldDisplay;
              }
              // @ts-ignore -- support Safari < 16.4 (~90% of Safari users as of this writing)
            } else if (containerRef.current.webkitRequestFullScreen) {
              // @ts-ignore
              await containerRef.current.webkitRequestFullScreen();
            }
            if (typeof window !== "undefined") {
              fsChangeHandler.current = () => {
                if (!document.fullscreenElement) {
                  updateDisplay(oldDisplay);
                }
              };
              document.addEventListener(
                "fullscreenchange",
                fsChangeHandler.current,
              );
            }
          } else {
            // we're on iOS Safari, which doesn't support custom fullscreen mode
            newDisplay = "expanded";
            mediaRef.current.nativeFullscreen();
          }
        } catch (e) {
          newDisplay = "expanded";
        }
      } else {
        newDisplay = "expanded";
      }
    } else if (displayRef.current === "fullscreen") {
      // exit fullscreen
      if (typeof document !== "undefined") {
        document.removeEventListener(
          "fullscreenchange",
          fsChangeHandler.current,
        );
        try {
          if (document.exitFullscreen) {
            await document.exitFullscreen();
            // @ts-ignore -- support Safari < 16.4
          } else if (document.webkitCancelFullscreen()) {
            // @ts-ignore
            await document.webkitCancelFullscreen();
          }
        } catch (e) {
          // probably already out of fullscreen mode
          console.error(e);
        }
      }
    }

    setTimeout(async () => {
      if (newDisplay === "hidden") {
        playerEndedTrackRef.current("cancel");
        mediaRef.current?.pause();
        clearQueue();
      }

      if (newDisplay === "expanded" && displayRef.current !== "expanded") {
        maximizedPlayer();
      } else if (newDisplay === "collapsed") {
        minimizedPlayer();
      }
      setDisplay(newDisplay);
      requestAnimationFrame(() => setInTransition(false));
    }, MEDIA_TRANSITION_MS);
  };

  const updateBgId = async (newBgId: number) => {
    setBgId(newBgId);
    await bgLoadRequest();
    if (!mediaRef.current.paused) await mediaRef.current.playBackground();
  };

  const updateSubtitles = (newSubtitleIndex: number | null) => {
    if (mediaRef.current?.hls) {
      if (newSubtitleIndex !== null) {
        mediaRef.current.hls.subtitleTrack = newSubtitleIndex;
      }
      mediaRef.current.hls.subtitleDisplay = false;
    }
    setSubtitleIdx(newSubtitleIndex);
  };
  //#endregion
  //#region Callbacks
  const handleKey = useCallback(
    (e: KeyboardEvent) => {
      if (
        ["input", "textarea"].includes(
          (e.target as HTMLElement).nodeName.toLowerCase(),
        )
      )
        return;
      if (e.code === "Space") {
        e.stopPropagation();
        e.preventDefault();
        if (paused) play();
        else pause();
      } else if (e.code === "Escape") {
        // this selector is particularly non-specific, but for the purposes of canceling "Esc to close" behavior,
        // it is sufficient. The maximized player is an open dialog, and in theory, there should be no other
        // dialogs open unless they are ON TOP of that. And none of this prevents the use of the "Collapse" button.
        const openDialogs = document.querySelectorAll('[data-state="open"]');
        if (display === "expanded") {
          if (openDialogs.length > 1) return; // don't close the media player if the settings menu is open
          e.stopPropagation();
          e.preventDefault();
          updateDisplay("collapsed");
        }
        // closing fullscreen with Escape is already handled natively by the browser, which is one reason we
        // listen to the fullscreenchange event
      }
    },
    [paused, display, play, pause, updateDisplay],
  );

  const adjustSpeed = useCallback(() => {
    if (
      mediaRef.current.playbackRate !== speed &&
      currentItemRef.current?.selected_audio?.speed_changes_enabled
    ) {
      mediaRef.current.playbackRate = speed;
    }
  }, [speed]);

  const handleEnd = useCallback(
    async (skip: boolean) => {
      const next = await forward(skip);
      if (next) {
        if (next === currentItem) return play();

        await loadRequest();
        await play();
        adjustSpeed();
      } else if (display !== "collapsed") {
        await updateDisplay("collapsed");
      }
    },
    [forward, currentItem, adjustSpeed, display],
  );

  const handleReverse = useCallback(async () => {
    const prev = back();
    if (prev) {
      if (prev === currentItem) return play();

      await loadRequest();
      await play();
      adjustSpeed();
    }
  }, [back, currentItem, adjustSpeed]);

  const resetAsNeeded = useCallback(() => {
    if (document.visibilityState === "visible") {
      if (mediaRef.current) {
        if (mediaRef.current.duration !== duration) {
          setDuration(mediaRef.current.duration);
        }
        if (mediaRef.current.paused !== paused) {
          setPaused(mediaRef.current.paused);
        }
        if (mediaRef.current.playbackRate !== speed) {
          setSpeed(mediaRef.current.playbackRate);
        }
        if (mediaRef.current.volume !== volume) {
          setVolume(mediaRef.current.volume);
        }
        if (Math.abs(mediaRef.current.currentTime - currentTime) > 1) {
          setCurrentTime(mediaRef.current.currentTime);
          if (mediaRef.current.currentTime === 0 && !mediaRef.current.paused) {
            setPaused(true);
            mediaRef.current.reset();
          }
        }
      }
    }
  }, [duration, paused, speed, volume, currentTime]);
  //#endregion

  const MediaSession = useMediaSessionAPI({
    handleEnd,
    handleReverse,
    mediaRef,
  });

  //#region Effects
  useEffect(() => {
    playerInitialized();

    return () => {
      playerDestroyed();
    };
  }, []);

  useEffect(() => {
    MediaSession.establishStandardHandlers(
      play,
      pause,
      () => updateDisplay("hidden"),
      currentItemRef.current?.type === "radio_song",
    );

    return () => {
      MediaSession.destroyStandardHandlers();
    };
  }, [MediaSession]);

  useEffect(() => {
    MediaSession.establishTrackHandlers();

    return () => {
      MediaSession.destroyTrackHandlers();
    };
  }, [MediaSession]);

  useEffect(() => {
    if (
      !currentItem ||
      currentItem.type === "radio_song" ||
      currentItem.prayer.is_song
    ) {
      MediaSession.destroySeekHandlers();
    } else {
      MediaSession.establishSeekHandlers();
    }
  }, [currentItem, MediaSession]);

  useEffect(() => {
    mediaSessionRef.current = MediaSession;
  }, [MediaSession]);

  useEffect(() => {
    MediaSession.updatePlaybackState(paused, display);
  }, [MediaSession, paused, display]);

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.addEventListener("visibilitychange", resetAsNeeded);
    }
    if (typeof window !== "undefined") {
      // we need this because visibilitychange doesn't fire when you lock the screen and unlock it again without
      // changing apps / tabs / windows
      window.addEventListener("focus", resetAsNeeded);
    }

    return () => {
      if (typeof document !== "undefined") {
        document.removeEventListener("visibilitychange", resetAsNeeded);
      }
      if (typeof window !== "undefined") {
        window.removeEventListener("focus", resetAsNeeded);
      }
    };
  }, [resetAsNeeded]);

  useEffect(() => {
    displayRef.current = display;

    setVideoWidth(mediaRef.current?.width ?? 64);
  }, [display]);

  useEffect(() => {
    bgAudioSrcRef.current = bgAudioSrc;
  }, [bgAudioSrc]);

  useEffect(() => {
    setMediaSrc((previousValue) => {
      const simpleReturn: typeof mediaSrc = [
        currentItem?.selected_audio.hls,
        currentItem?.selected_audio.url,
      ];

      if (!Array.isArray(previousValue) || previousValue.length !== 2) {
        return simpleReturn;
      }

      if (currentItem?.selected_audio.url !== previousValue[1]) {
        return simpleReturn;
      }

      if (!isEqual(currentItem?.selected_audio.hls, previousValue[0])) {
        return simpleReturn;
      }

      return previousValue;
    });

    setMediaType(currentItem?.selected_audio?.media_type ?? "audio");

    if (!currentItem?.selected_audio?.bg_sounds_enabled) {
      mediaRef.current.pauseBackground();
    }

    audioUuid.current = uuid();

    currentItemRef.current = currentItem;
  }, [currentItem]);

  useEffect(() => {
    if (mediaType !== "video") updateSubtitles(null);
  }, [mediaType]);

  useEffect(() => {
    goForwardRef.current = handleEnd;
  }, [handleEnd]);

  useEffect(() => {
    if (!currentItem) {
      updateDisplay("hidden");
    } else if (
      currentItem.selected_audio.media_type === "video" &&
      ["collapsed", "hidden"].includes(display)
    ) {
      updateDisplay("expanded");
    } else if (display === "hidden") {
      updateDisplay("collapsed");
    } else if (
      display === "fullscreen" &&
      currentItem.selected_audio.media_type !== "video"
    ) {
      updateDisplay("expanded");
    }
  }, [currentItem]);

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.addEventListener("keydown", handleKey);
    }

    return () => {
      if (typeof document !== "undefined") {
        document.removeEventListener("keydown", handleKey);
      }
    };
  }, [handleKey]);

  useEffect(() => {
    if (backgrounds.isSuccess) {
      const idToFind = bgId ?? user.data?.background_track_id ?? null;
      if (idToFind !== null) {
        const bg = backgrounds.data.find(
          (qi) => qi.selected_audio.id === idToFind,
        );
        if (!bg) {
          setBgAudioSrc([null, null]);
          setBgId(null);
        } else {
          const newSrc: typeof bgAudioSrc = [
            bg.selected_audio.hls,
            bg.selected_audio.url,
          ];

          if (!bgAudioSrc?.[1]) {
            setBgAudioSrc(newSrc);
            setBgId(idToFind);
          } else if (forceBgUpdate.current) {
            if (!isEqual(newSrc, bgAudioSrc)) {
              setBgAudioSrc(newSrc);
              setBgId(idToFind);
            }
            forceBgUpdate.current = false;
          } else {
            const oldUrl = new URL(bgAudioSrc[1]);
            const newUrl = new URL(bg.selected_audio.url);

            if (
              `${oldUrl.origin}${oldUrl.pathname}` !==
              `${newUrl.origin}${newUrl.pathname}`
            ) {
              setBgAudioSrc(newSrc);
              setBgId(idToFind);
            }
          }
        }
      } else if (
        bgAudioSrc[0] !== null ||
        bgAudioSrc[1] !== null ||
        bgId !== null
      ) {
        setBgAudioSrc([null, null]);
        setBgId(null);
      }
    }
  }, [backgrounds.data, user.data, bgId]);

  useEffect(() => {
    // reacting to display, because we sometimes destroy/create the element with those changes,
    // and mediaSrc, because a new src on a media object resets its volume
    if (mediaRef.current && mediaRef.current.volume !== volume) {
      mediaRef.current.volume = volume;
    }
  }, [volume, display, mediaSrc]);
  //#endregion

  const cancelSleepTimer = () => {
    if (sleepTimer.current) clearTimeout(sleepTimer.current);
    setSleepTimerTarget(null);
    sleepTimer.current = null;
  };

  const loadRequest = () => {
    const promiseId = uuid();
    return new Promise<void>((resolve) => {
      const newObj = { ...loadRequestPromiseResolver.current };
      newObj[promiseId] = resolve;
      loadRequestPromiseResolver.current = newObj;
    }).finally(() => {
      delete loadRequestPromiseResolver.current[promiseId];
    });
  };

  const bgLoadRequest = () => {
    return new Promise<void>((resolve) => {
      const newArr = [...bgLoadRequestPromiseResolver.current];
      newArr.push(resolve);
      bgLoadRequestPromiseResolver.current = newArr;
    });
  };

  const playNew: MediaContextShape["playNew"] = useCallback(
    async (item) => {
      if (
        item.type !== "audio" ||
        item.id !== currentItemRef.current?.selected_audio?.id
      ) {
        await newQueue({ items: [item] });
        await loadRequest();
        if (
          currentItemRef.current?.selected_audio?.resumes_at &&
          mediaRef.current
        ) {
          mediaRef.current.currentTime =
            currentItemRef.current.selected_audio.resumes_at / 1000;
          setAutoResumed(true);
        }
      }
      await play();
      adjustSpeed();
    },
    [newQueue, adjustSpeed],
  );

  const goToQueueId: MediaContextShape["goToQueueId"] = useCallback(
    async (uuid) => {
      skipTo(uuid);
      await loadRequest();
      await play();
      adjustSpeed();
    },
    [skipTo, adjustSpeed],
  );

  const switchTo: MediaContextShape["switchTo"] = useCallback(
    async (item) => {
      await swap(item);
      await loadRequest();
      await play();
      adjustSpeed();
    },
    [swap, adjustSpeed],
  );

  const add: MediaContextShape["addToQueue"] = useCallback(
    async (addItem) => {
      if (!currentItem) {
        await playNew({ id: addItem.id, type: addItem.type });
        return [];
      }
      return addToQueue(addItem);
    },
    [currentItem, playNew, addToQueue],
  );

  const setBgVolumeContext = (v: number) => {
    setBgVolume(v);
    if (mediaRef.current) mediaRef.current.bgVolume = v;
  };

  const setSleepTimer = (seconds: number, timeMinStr: string) => {
    const ms = seconds * 1000;
    setSleepTimerStart(Date.now());
    setSleepTimerTarget(Date.now() + ms);
    if (sleepTimer.current) clearTimeout(sleepTimer.current);
    sleepTimer.current = setTimeout(() => {
      playerEndedTrackRef.current("timer");
      timerEndedRef.current(timeMinStr);
      pause(true);
    }, ms);
  };

  const setSpeedContext = (s: number) => {
    if (mediaRef.current) mediaRef.current.playbackRate = s;
  };

  const setTimeContext = (t: number) => {
    if (mediaRef.current) {
      draggedTimeSlider();
      mediaRef.current.currentTime = t;
    }
  };

  const setVolumeContext = (v: number) => {
    if (mediaRef.current) mediaRef.current.volume = v;
  };

  const skipBack = useCallback(() => {
    if (currentItem?.type === "radio_song") return null;
    if (currentItem?.prayer?.is_song && mediaRef.current.currentTime < 5) {
      tappedRWRef.current();
      return handleReverse();
    } else if (currentItem?.prayer?.is_song && mediaRef.current) {
      tappedRWRef.current();
      mediaRef.current.currentTime = 0;
    } else if (mediaRef.current) {
      tappedSkipBackRef.current();
      mediaRef.current.currentTime = Math.max(
        0,
        mediaRef.current.currentTime - 10,
      );
    }
  }, [currentItem, handleReverse]);

  const skipForward = useCallback(() => {
    if (currentItem.type === "radio_song" || currentItem.prayer.is_song) {
      tappedFFWRef.current();
      return handleEnd(true);
    }
    if (mediaRef.current) {
      tappedSkipForwardRef.current();
      mediaRef.current.currentTime = Math.min(
        mediaRef.current.duration,
        mediaRef.current.currentTime + 10,
      );
    }
    return Promise.resolve();
  }, [currentItem, handleEnd]);

  const restart = () => {
    if (mediaRef.current) {
      mediaRef.current.currentTime = 0;
      setAutoResumed(false);
    }
  };

  const skipIntro = useCallback(() => {
    if (mediaRef.current && currentItem) {
      mediaRef.current.currentTime =
        currentItem.selected_audio.intro_ends_at / 1000;
    }
  }, [currentItem]);

  const handleError = async (error: DOMException | MediaError) => {
    if (currentItemRef.current) {
      playerEndedTrackRef.current("error");
      currentItemRef.current.errored = true;
      Bugsnag.notify(error.message);
      try {
        await refresh();
      } catch (e) {
        if (e.error === 100) {
          // refresh is too soon
          throw error;
        }
      }
    }
  };

  const handleElementError = async (event: { target: HTMLAudioElement }) => {
    if (event?.target?.error) await handleError(event.target.error);
  };

  const handleBackgroundError = async (error: DOMException | MediaError) => {
    forceBgUpdate.current = true;
    await backgrounds.refetch();

    if (currentItemRef.current && bgAudioSrcRef.current) {
      Bugsnag.notify(error.message);
    }
  };

  const handleBgElementError = async (event: { target: HTMLAudioElement }) => {
    if (event?.target?.error) await handleBackgroundError(event.target.error);
  };

  const elementContextRef = useRef<MediaElementContextShape>({
    onLoaded: () => {
      if (Object.values(loadRequestPromiseResolver.current).length) {
        Object.values(loadRequestPromiseResolver.current).map((r) => r());
      }
      audioStartedRef.current();
      playerBeganTrackRef.current();
      mediaSessionRef.current?.updateDuration();
      mediaSessionRef.current?.updateMetadata();
      if (mediaRef.current?.textTracks) {
        for (let t = 0; t < mediaRef.current.textTracks.length; t++) {
          if (mediaRef.current.textTracks[t].mode === "showing") {
            mediaRef.current.textTracks[t].mode = "hidden";
          }
        }
      }
      setVideoWidth(mediaRef.current?.width ?? 64);
    },
    onBgElementError: handleBgElementError,
    onBgError: handleBackgroundError,
    onBgLoaded: () => {
      if (bgLoadRequestPromiseResolver.current?.length) {
        bgLoadRequestPromiseResolver.current.map((r) => r());
        bgLoadRequestPromiseResolver.current = [];
      }
    },
    onEnded: async () => {
      setIsPostPrayerDownloadModalReady(true);
      playerEndedTrackRef.current("complete");
      if (!audioUuid.current) audioUuid.current = uuid();
      await requestUpdateMediaTime.request({
        id: audioUuid.current,
        audio_id: currentItemRef.current.selected_audio.id,
        content_id: getContentId(currentItemRef.current),
        content_type: currentItemRef.current.type,
        current_position: (mediaRef.current?.duration ?? 0) * 1000,
        duration: (mediaRef.current?.duration ?? 0) * 1000,
      });
      if (currentItemRef.current.type === "prayer") {
        queryClient.invalidateQueries({
          queryKey: [
            keyCollection({ id: currentItemRef.current.collection.id }),
          ],
        });
        queryClient.invalidateQueries({
          queryKey: [keyPrayer({ id: currentItemRef.current.prayer.id })],
        });
        complete(currentItemRef.current.prayer.id);
      }

      goForwardRef.current(false);
    },
    onElementError: handleElementError,
    onError: handleError,
    updateDuration: (d: number) => setDuration(d),
    updatePaused: (p: boolean) => setPaused(p),
    updateSpeed: (s: number) => {
      setSpeed(s);
      mediaSessionRef.current?.updateSpeed();
    },
    updateSubtitles: (tracks: MediaPlaylist[] | TextTrackList) => {
      let arr: MediaTextTrack[];
      if (Array.isArray(tracks))
        arr = tracks.map((itm) => new MediaTextTrack(itm));
      else arr = Array.from(tracks).map((itm) => new MediaTextTrack(itm));
      setSubtitles(arr);
    },
    updateTime: (t: number) => {
      setCurrentTime(t);
      mediaSessionRef.current?.updateMediaPosition();
      if (
        Date.now() - backendPingTime.current > 5000 &&
        mediaRef.current?.duration
      ) {
        backendPingTime.current = Date.now();
        if (!audioUuid.current) audioUuid.current = uuid();
        // intentionally ignore this promise
        requestUpdateMediaTime.request({
          id: audioUuid.current,
          audio_id: currentItemRef.current.selected_audio.id,
          content_id: getContentId(currentItemRef.current),
          content_type: currentItemRef.current.type,
          current_position: t * 1000,
          duration: (mediaRef.current?.duration ?? 0) * 1000,
        });
      }
      if (
        mediaRef.current?.currentTime >
        currentItemRef.current?.selected_audio?.resumes_at / 1000 + 10
      ) {
        setAutoResumed(false);
      }
    },
    updateVolume: (v: number) => setVolume(v),
  });

  return (
    <MediaContext.Provider
      value={{
        addToQueue: add,
        autoResumed,
        bgId,
        bgTitle: bgId
          ? (backgrounds.data?.find((qi) => qi.selected_audio.id === bgId)
              ?.prayer.title ?? null)
          : null,
        bgVolume,
        cancelSleepTimer,
        currentTime,
        display,
        duration,
        goToQueueId,
        inTransition,
        isPostPrayerDownloadModalReady,
        mediaRef,
        mediaType,
        pause,
        paused,
        play,
        playNew,
        restart,
        setBgId: updateBgId,
        setBgVolume: setBgVolumeContext,
        setIsPostPrayerDownloadModalReady,
        setSleepTimer,
        setSpeed: setSpeedContext,
        setSubtitles: updateSubtitles,
        setTime: setTimeContext,
        setVolume: setVolumeContext,
        skipBack,
        skipForward,
        skipIntro,
        sleepTimerStart,
        sleepTimerTarget,
        speed,
        switchTo,
        subtitleIdx,
        subtitles,
        volume,
      }}
    >
      {children}
      <Player
        key={"mediaPlayerComponent"}
        mediaDetails={currentItem}
        onClose={() => updateDisplay("hidden")}
        onExpand={() => updateDisplay("expanded")}
        onCollapse={() => updateDisplay("collapsed")}
        onFullscreen={() => updateDisplay("fullscreen")}
        fullscreenContainer={containerRef}
        videoWidth={videoWidth}
      >
        <MediaElementContext.Provider value={elementContextRef.current}>
          <Elements
            ref={mediaRef}
            bgAudioSrc={bgAudioSrc}
            imgSrc={getContentImage(currentItem)}
            mediaSrc={mediaSrc}
            mediaType={mediaType}
            mediaTitle={getContentTitle(currentItem)}
            bgVolume={bgVolume}
            shouldPlayBg={
              currentItem?.selected_audio?.bg_sounds_enabled ?? false
            }
          />
        </MediaElementContext.Provider>
      </Player>
    </MediaContext.Provider>
  );
};
