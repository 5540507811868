import { URL_TRIVIA_IMAGE } from "../../src";

export const mockDataTrivia = {
  color: "rgb(56, 4, 103)",
  imgSrc: {
    large: URL_TRIVIA_IMAGE,
  },
  questions: [
    {
      id: 4862,
      text: "On this day in 1506, Pope Julius II laid the foundation stone for the current building of which basilica in Vatican City?",
      category: "History",
      explanation: null,
      user_answer: {
        answer_id: 25219,
        seconds_elapsed: 0,
      },
      answers: [
        {
          id: 25222,
          text: "St. John Lateran Basilica",
          correct: false,
          percentage: 0.5,
        },
        {
          id: 25219,
          text: "St. Peter's Basilica",
          correct: true,
          percentage: 0.5,
        },
        {
          id: 25220,
          text: "St. Mary Major Basilica",
          correct: false,
          percentage: 0,
        },
        {
          id: 25221,
          text: "St. Paul Outside the Walls Basilica",
          correct: false,
          percentage: 0,
        },
      ],
    },
    {
      id: 4863,
      text: "Which saint is often depicted with a lamb?",
      category: "Saints",
      explanation: null,
      user_answer: {
        answer_id: 25225,
        seconds_elapsed: 0,
      },
      answers: [
        {
          id: 25226,
          text: "St. Brigid",
          correct: false,
          percentage: 0,
        },
        {
          id: 25223,
          text: "St. Agnes",
          correct: true,
          percentage: 0,
        },
        {
          id: 25224,
          text: "St. Luke",
          correct: false,
          percentage: 0.5,
        },
        {
          id: 25225,
          text: "St. Roch",
          correct: false,
          percentage: 0.5,
        },
      ],
    },
    {
      id: 4864,
      text: 'Which book of the Bible is NOT classified as a "Biblical Novella"?',
      category: "Scripture",
      explanation: null,
      user_answer: {
        answer_id: 25229,
        seconds_elapsed: 0,
      },
      answers: [
        {
          id: 25227,
          text: "Sirach",
          correct: true,
          percentage: 0.5,
        },
        {
          id: 25228,
          text: "Tobit",
          correct: false,
          percentage: 0,
        },
        {
          id: 25229,
          text: "Judith",
          correct: false,
          percentage: 0.5,
        },
        {
          id: 25230,
          text: "Esther",
          correct: false,
          percentage: 0,
        },
      ],
    },
    {
      id: 4865,
      text: "Which plant gets its name because it is often in full bloom around June 24th, the birthday of its namesake?",
      category: "Pop Culture",
      explanation: null,
      user_answer: {
        answer_id: 25234,
        seconds_elapsed: 1,
      },
      answers: [
        {
          id: 25234,
          text: "Rose of Sharon",
          correct: false,
          percentage: 0.5,
        },
        {
          id: 25233,
          text: "Joseph's Coat",
          correct: false,
          percentage: 0,
        },
        {
          id: 25231,
          text: "St. John's Wort",
          correct: true,
          percentage: 0,
        },
        {
          id: 25232,
          text: "St. Bernard's Lily",
          correct: false,
          percentage: 0.5,
        },
      ],
    },
    {
      id: 4866,
      text: "Which virtue is a commitment to truthfulness and accuracy in speech and action?",
      category: "Spirituality",
      explanation: null,
      user_answer: {
        answer_id: 25235,
        seconds_elapsed: 1,
      },
      answers: [
        {
          id: 25238,
          text: "Taciturnity",
          correct: false,
          percentage: 0,
        },
        {
          id: 25237,
          text: "Diligence",
          correct: false,
          percentage: 0,
        },
        {
          id: 25236,
          text: "Solicitude",
          correct: false,
          percentage: 0,
        },
        {
          id: 25235,
          text: "Veracity",
          correct: true,
          percentage: 1,
        },
      ],
    },
  ],
  summary: {
    total_seconds_elapsed: 12030,
    streak: 12,
    longest_streak: 30,
  },
} as const;
